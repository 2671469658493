/*
|--------------------------------------------------------------------------
| Custom Javascript code
|--------------------------------------------------------------------------
|
| Now that you configured your website, you can write additional Javascript
| code inside the following function. You might want to add more plugins and
| initialize them in this file.
|
*/

$(function () {
  function rescaleCaptcha() {
    var width = $(".g-recaptcha").parent().width();
    var scale;
    if (width < 302) {
      scale = width / 302;
    } else if (width >= 349) {
      scale = 1.15;
    } else if (width >= 330) {
      scale = 1.09;
    } else {
      scale = 1.0;
    }

    $(".g-recaptcha").css("transform", "scale(" + scale + ")");
    $(".g-recaptcha").css("-webkit-transform", "scale(" + scale + ")");
    $(".g-recaptcha").css("transform-origin", "0 0");
    $(".g-recaptcha").css("-webkit-transform-origin", "0 0");
  }

  rescaleCaptcha();
  $(window).resize(function () {
    rescaleCaptcha();
  });
});

var lozad = require("lozad");

// Initialize library
lozad(".lozad", {
  load: function (el) {
    el.src = el.dataset.src;
    el.onload = function () {
      el.classList.add("fadeInUp");
    };
  },
}).observe();

const obs = lozad(".lozad-fitur", {
  rootMargin: "0px", // syntax similar to that of CSS Margin
  threshold: 0.1, // ratio of element convergence
  load: function (gi) {
    gi.src = gi.dataset.src;
    gi.onload = function () {
      gi.classList.add("fadeInUp");
    };
  },
});

obs.observe();

// if ($(".pageloader").length) {
//   $(".pageloader").toggleClass("is-active");
//   $(window).on("load", function () {
//     var pageloaderTimeout = setTimeout(function () {
//       $(".pageloader").toggleClass("is-active");
//       $(".infraloader").toggleClass("is-active");
//       clearTimeout(pageloaderTimeout);
//     }, 700);
//   });
// }
